<template>
  <el-dialog
      title="导入失败列表"
      :visible.sync="show"
      class="choose-selectedPlan-importFail"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :beforeClose="beforeClose"
      append-to-body
      width="70%"
  >
    <el-table :data="virtualTable" style="width: 100%" max-height="600" fit align="center">
      <el-table-column type="index" label="序号" :index="indexMath"></el-table-column>
      <el-table-column prop="xm" label="姓名"></el-table-column>
      <el-table-column prop="sfzhm" label="身份证号码" width="180"></el-table-column>
      <el-table-column prop="czdz" label="常居住地"></el-table-column>
      <el-table-column prop="errormsg" label="失败原因" class="failReason"></el-table-column>
    </el-table>
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="query.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="query.total"
    ></el-pagination>
  </el-dialog>
</template>

<script>
export default {
  // 导入失败
  name: 'importFail',
  props: {
    title: {
      tyle: String,
    },
    progress: Object,
  },
  components: {},
  data() {
    return {
      show: false,
      table: [],
      query: {
        size: 100,
        page: 1,
        total: 0,
      },
      virtualTable: [],
    };
  },
  methods: {
    indexMath(index) {
      return index + (this.query.page - 1) * this.query.size + 1;
    },
    // 设置虚拟现实的数据
    setVirtualTable() {
      let {page, size} = this.query;

      this.virtualTable = this.table.slice((page - 1) * size, (page - 1) * size + size);
      console.log(this.virtualTable);
    },
    init() {
      console.log(this.progress)
      this.table = this.progress.errors;
      this.query.total = this.table.length;
      return this.setVirtualTable();
    }, // 每页条数变化
    handleSizeChange(data) {
      this.query.size = data;
      this.page = 1;
      return this.setVirtualTable();
    },
    // 当前页数变化
    handleCurrentChange(data) {
      console.log(data);
      this.query.page = data;
      return this.setVirtualTable();
    },
    beforeClose(done) {
      done();
      this.$store.commit('changeComponent', '');
      this.toggle(false);
      this.$emit('closeAllDislog')
    },
    download() {
      this.$refs.code.click();
    },
    toggle(show) {
      this.show = show;
    },
  },
  mounted() {
  },
  created() {
    return this.init();
  },
  computed: {},
  watch: {
    progress: {
      handler:function () {
        console.log(this,'watch')
        this.init()
      },
      deep: true
    }
  },
};
</script>

<style scoped lang="scss">
.el-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70%;
}

::v-deep .el-table__row {
  .el-table__cell:nth-of-type(5) {
    color: #ec808d;
  }
}

.el-table {
  border: 1px solid rgba(232, 232, 232, 1);
  border-bottom: none;
  margin-bottom: 20px;
}

.el-pagination {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0);
}

::v-deep .el-dialog__title {
  font-weight: bold;
}
</style>
